<template>
  <div class="main">
    <div class="top" :style="{backgroundImage:'url('+require('@/assets/head.png') +')'}">
      <img :src="icon" class="icon" alt="download game">
      <div class="txt">
        <div class="appname">{{ title }}</div>
        <div class="sologan">{{ slogan }}</div>
      </div>
    </div>
    <!--first section: jumbotron-->
    <div class="empty"></div>
    <img :src="bg" class="mian-image">
    <!-- <img @click="adrdl" src="@/assets/btn_adr.png" class="img-full" alt="download game"> -->
    <div class="btns">
      <img @click="adrdl" src="@/assets/btn_adr.png" class="img-full" alt="download game">
    </div>
    <!-- <div class="hint">
      提示：游戏已
      <img src="@/assets/secure.png" style="width: 4vw;">
      <span class="secure">通过安全检测</span>
      ，下载完成如果出现“
      <span class="warn">安装未知来源的应用程序</span>
      ”等安全提示，请放心安装。
    </div> -->
    <div class="hint">
      Lời khuyên: Trò chơi đã
      <img src="@/assets/secure.png" style="width: 4vw;">
      <span class="secure">Thông qua kiểm tra bảo mật</span>
      , tải xong nếu xuất hiện dòng nhắc nhở như "
      <span class="warn">cài đặt ứng dụng không rõ nguồn gốc</span>
      " , hoặc "
      <span class="warn">cảnh báo an toàn</span>
      " thì các bạn cứ yên tâm cài đặt nhé.
    </div>
    <app-overlay :show.sync="show">
      <div>
        <div class="arrow">
          <img src="@/assets/arrow.png" width="200">
        </div>
      </div>
    </app-overlay>
    <!--second section: screenshots-->
    <div class="sec-3">
      <div class="title">
        <img src="@/assets/sep.png" style="width: 100%" alt="" class="img-full-width">
      </div>
      <div v-for="(text, idx) in texts" :key="idx" class="txt">{{text}}</div>
      <div class="swiper-box">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(link, idx) in screenshots" :key="idx">
            <img :src="link" alt="" style="width: 100%">
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination" slot="pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    game: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    slogan: {
      type: String,
      default: ''
    },
    imgName: {
      type: String,
      default: ''
    },
    introduce: {
      type: String,
      default: ''
    },
    dlForAdr: {
      type: String,
      default: ''
    },
    texts: {
      type: Array,
      default: () => []
    },
    kind: {
      type: String,
      default: 'game'
    },
    carousel: {
      type: Array,
      default: () => [1, 2, 3]
    }

  },
  computed: {
    bg () {
      return require('@/assets/' + this.game + '/' + this.kind + '.png')
    },
    icon () {
      return require('@/assets/' + this.game + '/icon.png')
    },
    screenshots () {
      return this.carousel.map((idx) => require('@/assets/' + this.game + '/' + idx))
    }
  },
  data () {
    return {
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        loop: true,
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      },
      top_btns: [
      ],
      show: false
    }
  },
  methods: {
    getBgImg () {
      return '../../assest' + this.getImg() + '_bg_new.png'
    },
    getImg () {
      return this.imgName || this.game
    },
    adrdl () {
      this.downdload(this.dlForAdr)
    },
    downdload (url) {
      if (!url) {
        window.alert('no url...')
        return
      }
      if (this.inWechat()) {
        this.show = true
      } else {
        // console.log('URL', url)
        window.location.assign(url)
      }
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/utils.scss";

.main {
  .top {
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -2px;
    height: 16vw;
    max-width: 100vw;
    z-index: 1024;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    .icon{
      height: 80%;
      margin-left: 2vw;
    }
    .txt{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      margin-left: 2vw;
      font-family: 'Times New Roman', Times, serif;
      .appname{
        color:rgb(255, 255, 255);
        font-size: 3.6vw;
      }
      .sologan{
        margin-top: 1vw;
        color: rgb(127, 156, 187);
        font-size: 3vw;
      }
    }
  }
  .empty{
    height: 16vw;
  }
  .arrow {
    top: -25%;
    right: -12%;
    text-align: right;

    img {
      width: auto;
      height: 7rem;
      margin-top: -18rem;
      margin-left: 1rem;
    }
  }
  .mian-image {
    width: 100%;
  }
  .btns {
    position: relative;
    padding-top: 4vw;
    text-align: center;
    img {
      width: 25%;
      margin: 2% 2%;
    }
  }
  .hint {
    margin: 0 5vw;
    font-size: 2.8vw;
    font-weight: 900;
    color: cornsilk;
    .secure {
      color: #17b82c;
    }
    .warn {
      color: orange;
    }
  }
  .sec-3 {
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10vw;
    .txt{
      width: 90vw;
      font-size: 2.8vw;
      color: cornsilk;
      line-height: 3.5vw;
      margin-bottom: 2vw;
    }
    .title {
      padding: 10px 0;
    }
  }
  .copy {
    padding: .5rem .5rem;
    p {
      color: #375a8a;
      font-size: .7rem;
      padding: .5rem 0 0;
    }
  }
}
</style>

<style lang="scss">
.swiper-box {
  padding: 2vw 5vw;
  width: 100%;
  .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      width: 2vw;
      height: 2vw;
      background-color: #dab24d;
      margin: 0 1vw;
    }
    // @media only screen and (min-width: 768px) {
    //   .swiper-pagination-bullet {
    //     width: 20px;
    //     height: 20px;
    //   }
    // }
  }
}
</style>
