<template>
  <game-base
    game="fish999"
    title="999 Bắn Cá Nổ Hũ - Ban Ca ica"
    slogan="Bắn Cá Nổ Hũ"
    :dl-for-adr="url"
    :texts="texts"
    :kind="'game'"
    :carousel="carousel"
  />
</template>

<script>
import GameBase from './GameBase.vue'
import env from '@/utils/env'

export default {
  components: {
    GameBase
  },
  data () {
    return {
      url: '',
      carousel: [
        '1.webp',
        '2.webp',
        '3.webp',
        '4.webp'
      ],
      texts: [
        '999 Bắn Cá Nổ Hũ – Ban Ca ica :BẮN CÁ ONLINE THẬT ĐÃ TAY.',
        '999 Bắn Cá Nổ Hũ là tựa game hàng đầu của dòng game bắn cá với đồ họa 3D siêu mượt mà; hệ thống súng khủng – bắn cực đã tay & dàn đồng ca hoành tráng nhất đại dương với trên 50 loại cá.',
        'Bạn ạn không biết chơi bắn cá hoặc chưa từng chơi bắn cá siêu thị? Đừng lo! 999 Bắn Cá Nổ Hũ chơi rất đơn giản:',
        '(1)Bước 1: Chọn mục tiêu muốn bắt. Cá càng lớn càng đẹp thì càng ăn xu được nhiều',
        '(2)Bước 2: Nhấn hoặc giữ ngón tay trên màn hình để bắn liên tục (free fire)',
        '(3)Bước 3: Di chuyển theo mục tiêu đã định để tăng cơ hội bắt được cá lớn.'
      ]
    }
  },
  created () {
    this.url = this.URLAndroid()
  },
  methods: {
    URLAndroid () {
      let url
      const chan = this.$route.query.chan
      if (chan) {
        url = env.download.chan
        url = url.replace('{chan}', chan)
      } else {
        url = env.download.raw
      }
      return url
    }
  }
}
</script>
